<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-3">
          <v-card-title>Reports</v-card-title>
          <v-row class="px-3">
            <v-col cols="12" sm="2">
              <v-select
                v-model="reportType"
                :items="reportTypes"
                label="Report Type"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="2" v-if="compShowSearchLocation">
              <v-select
                v-model="searchLocation"
                :items="searchLocations"
                label="Location"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <v-menu
                v-model="dateStartMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                clearable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    :label="compDateLabel1"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="dateStart"
                  @input="dateStartMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" v-if="compShowDateEnd">
              <v-menu
                v-model="dateEndMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                clearable
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    label="End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="dateEnd"
                  @input="dateEndMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="searchLocation && compShowUnitType" cols="12" sm="4">
              <v-combobox
                v-model="unitTypes"
                :items="compUnitTypes"
                label="Unit Type"
                multiple
                height="32"
                clearable
              ></v-combobox>
            </v-col>
          </v-row>
        </v-card>
        <Occupancy
          v-if="reportType === 'Occupancy'"
          :location="searchLocation"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          :unitTypes="unitTypes"
        />

        <Revenue
          v-if="reportType === 'Revenue'"
          :location="searchLocation"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          :unitTypes="unitTypes"
        />

        <!-- <v-card elevation="1" class="mb-3">
          <v-card-title>Revenue</v-card-title>

          <v-card elevation="1" class="ma-3 pa-2">
            <v-row class="d-flex justify-start">
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <v-card-subtitle class="text-left mb-0 pb-0"
                  ><sup
                    ><vue-json-to-csv
                      :json-data="compMasterRevenueData"
                      :csv-title="'transactions'"
                    >
                      <v-icon color="primary">mdi-file-download-outline</v-icon>
                    </vue-json-to-csv></sup
                  >
                  TOTAL REVENUE
                </v-card-subtitle>
                <p class="text-h4 text--primary mb-0 text-left pl-5">
                  {{
                    formatNumber(
                      compRevenueTotal.total +
                        compPaymentLinksBookingsSummary.total +
                        compPaymentLinksNoBookingsSummary.total
                    )
                  }}
                </p>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="1" class="ma-3 pa-2">
            <v-row class="d-flex justify-start">
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <v-card-subtitle class="text-left mb-0 p-0 pb-0"
                  ><sup
                    ><vue-json-to-csv
                      :json-data="compMasterRevenueDataBookings"
                      :csv-title="'transactions'"
                    >
                      <v-icon color="primary">mdi-file-download-outline</v-icon>
                    </vue-json-to-csv></sup
                  >
                  BOOKINGS REVENUE
                </v-card-subtitle>
                <p class="text-h4 text--primary mb-0 text-left pl-5">
                  {{
                    formatNumber(
                      compRevenueTotal.total +
                        compPaymentLinksBookingsSummary.total
                    )
                  }}
                </p>
              </v-col>

              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{
                    formatNumber(
                      compRevenueTotal.total +
                        compPaymentLinksBookingsSummary.storage
                    )
                  }}
                </p>
                <span>Storage</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksBookingsSummary.door) }}
                </p>
                <span>Door-to-Door</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksBookingsSummary.meeting) }}
                </p>
                <span>Meeting Room</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksBookingsSummary.document) }}
                </p>
                <span>Document Service</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksBookingsSummary.padlock) }}
                </p>
                <span>Padlock Removal</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksBookingsSummary.other) }}
                </p>
                <span>Other</span>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="1" class="ma-3 pa-2">
            <v-row class="d-flex justify-start">
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <v-card-subtitle class="text-left mb-0 p-0 pb-0"
                  ><sup
                    ><vue-json-to-csv
                      :json-data="compMasterRevenueDataPaymentLinks"
                      :csv-title="'transactions'"
                    >
                      <v-icon color="primary">mdi-file-download-outline</v-icon>
                    </vue-json-to-csv></sup
                  >
                  OTHER REVENUE
                </v-card-subtitle>
                <p class="text-left text-h4 text--primary mb-0 pl-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.total) }}
                </p>
              </v-col>

              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.storage) }}
                </p>
                <span>Storage</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.door) }}
                </p>
                <span>Door-to-Door</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.meeting) }}
                </p>
                <span>Meeting Room</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.document) }}
                </p>
                <span>Document Service</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.padlock) }}
                </p>
                <span>Padlock Removal</span>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="text-center mb-6">
                <p class="text-h5 text--primary mb-0 mt-5">
                  {{ formatNumber(compPaymentLinksNoBookingsSummary.other) }}
                </p>
                <span>Other</span>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="d-flex justify-start">
            <v-col cols="12" sm="6" class="text-center mb-6">
              <v-card-subtitle>With Bookings <sup>(THB)</sup></v-card-subtitle>
              <RevenuePie :paymentLinks="compPaymentLinksBookingsSummary" />
            </v-col>
            <v-col cols="12" sm="6" class="text-center mb-6">
              <v-card-subtitle
                >Without Bookings <sup>(THB)</sup></v-card-subtitle
              >
              <RevenuePie :paymentLinks="compPaymentLinksNoBookingsSummary" />
            </v-col>
          </v-row>
        </v-card> -->
        <!-- <v-card elevation="1" class="mb-3">
          <v-card-title
            ><div v-if="role === 'Admin'" style="margin-top: -3px;">
              <vue-json-to-csv
                :json-data="compBookings"
                :csv-title="'bookings'"
              >
                <v-icon color="primary">mdi-file-download-outline</v-icon>
              </vue-json-to-csv>
            </div>
            Booking Payment Transactions</v-card-title
          >
          <v-card-subtitle
            >Active: <strong>{{ compSummary.active }}</strong> ID Required:
            <strong>{{ compSummary.idRequired }}</strong> Overdue:
            <strong>{{ compSummary.overdue }}</strong></v-card-subtitle
          >
          <v-data-table
            :headers="headers"
            :search="search"
            :items="compBookings"
            item-key="_id"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:item.customerDetails="{ item }">
              <router-link v-bind:to="'/users/' + item.customerDetails._id"
                >{{ item.customerDetails.firstname }}
                {{ item.customerDetails.lastname }}</router-link
              >
            </template>
            <template v-slot:item.isSubscription="{ item }">
              <v-icon v-if="item.isSubscription" small>mdi-check-bold</v-icon>
            </template>
            <template v-slot:item.createdOn="{ item }">
              <FancyDate :date="item.createdOn" />
            </template>
            <template v-slot:item.dateStart="{ item }">
              <FancyDate :date="item.dateStart" />
            </template>
            <template v-slot:item.dateEnd="{ item }">
              <FancyDate :date="item.dateEnd" />
            </template>
            <template v-slot:item.unitDetails="{ item }">
              <router-link v-bind:to="'/Units/' + item.unitDetails._id">{{
                item.unitDetails.unitNumber
              }}</router-link>
            </template>
            <template v-slot:item.notes="{ item }">
              <v-tooltip v-if="item.notes" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-comment-text
                  </v-icon>
                </template>
                <span>{{ item.notes }}</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-flex xs12>
                  <TransactionCard
                    v-for="transaction in item.transactions"
                    :key="transaction._id"
                    :index="transaction._id"
                    :transaction="transaction"
                    class="my-3"
                  />
                </v-flex>
              </td>
            </template>
          </v-data-table>
        </v-card> -->

        <!-- <v-card elevation="1" class="mb-3">
          <v-card-title
            ><div style="margin-top: -3px;">
              <vue-json-to-csv :json-data="compUsers" :csv-title="'customers'">
                <v-icon color="primary">mdi-file-download-outline</v-icon>
              </vue-json-to-csv>
            </div>
            Customer Spending</v-card-title
          >
          <v-data-table
            :headers="customerSpending"
            :items="compUsers"
            :key="compUsers._id"
          >
            <template v-slot:item.enabled="{ item }">
              <v-icon v-if="item.enabled" small>mdi-check-bold</v-icon>
            </template>
            <template v-slot:item.note="{ item }">
              <v-tooltip v-if="item.note" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-comment-text
                  </v-icon>
                </template>
                <span>{{ item.note }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card> -->

        <ERP
          v-if="reportType === 'ERP'"
          :location="searchLocation"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          :unitTypes="unitTypes"
        />

        <Receipts
          v-if="reportType === 'Receipts'"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
// import TransactionCard from "@/components/transactionCard.vue";
import Occupancy from "@/components/Reports/Occupancy.vue";
import Revenue from "@/components/Reports/Revenue.vue";
import ERP from "@/components/Reports/ERP.vue";
import Receipts from "@/components/Reports/Receipts.vue";
// import RevenuePie from "@/components/Reports/RevenuePie.vue";
// import FancyDate from "@/components/fancyDate.vue";
import { mapGetters } from "vuex";
// import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Reports",
  components: {
    Occupancy,
    Revenue,
    ERP,
    Receipts
  },
  data: () => ({
    reportType: "",
    reportTypes: ["Occupancy", "Revenue", "ERP", "Receipts"],
    showInactive: false,
    searchCustomer: null,
    searchLocation: "ALL",
    searchLocations: ["ALL", "RAMA 9", "LKB", "LCB"],
    expanded: [],
    search: "",
    searchOmise: "",
    locations: ["RAMA 9", "LKB", "LCB"],
    dateStartMenu: false,
    dateStart: "",
    dateEndMenu: false,
    dateEnd: "",
    unitTypes: null
  }),
  computed: {
    ...mapGetters(["bookingsAndCustomers"]),
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    users() {
      return this.$store.state.users;
    },
    compUnitTypes() {
      let data = this.$store.state.units;
      if (this.searchLocation !== "ALL") {
        let results = data.filter(d => d.location === this.searchLocation);
        data = results;
      }
      let uniqueTypes = [...new Set(data.map(item => item.type))];
      uniqueTypes.sort();
      return uniqueTypes ? uniqueTypes : null;
    },
    compUsers() {
      let users = this.users;

      // remove disabled?
      if (!this.showDisabled) {
        users = users.filter(u => u.enabled === true);
      }

      // include only customers
      users = users.filter(u => u.role === "customer");

      // calculate YTD Spending
      users.forEach(obj => {
        obj.YTDSpending = this.parseYTDSpendingByID(obj._id);
      });

      // calculate YTD Spending and tier
      users.forEach(obj => {
        obj.tier = helpers.compYTDSpendingTier(obj.YTDSpending);
      });

      return users;
    },
    compShowSearchLocation() {
      return this.reportType === "ERP" || this.reportType === "Receipts"
        ? false
        : true;
    },
    compShowUnitType() {
      return this.reportType === "ERP" || this.reportType === "Receipts"
        ? false
        : true;
    },
    compShowDateEnd() {
      return this.reportType === "ERP" || this.reportType === "Receipts"
        ? false
        : true;
    },
    compDateLabel1() {
      return this.reportType === "ERP" || this.reportType === "Receipts"
        ? "Transaction Date"
        : "Start Date";
    }
  },
  methods: {
    formatStringNumber(s) {
      let num = String(s);
      num = num.replace(/[^\d.]/g, "");
      return Number(num);
    },
    formatNumber(n) {
      return Number(n).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    sortDates(a, b) {
      // Sort dates
      return moment(a.createdOn).isBefore(moment(b.createdOn));
    },
    isMatch(t) {
      if (
        typeof t._id !== "undefined" &&
        t._id !== null &&
        t._id.indexOf(this.searchOmise) != -1
      )
        return true;
      return false;
    },
    showToast() {
      this.$toasted
        .show("Thanks! ", {
          type: "success",
          icon: "mdi-thumb-up"
        })
        .goAway(12500);
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    }
  },
  mounted: function() {
    // set default dates
    this.dateStart = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    this.dateEnd = moment().format("YYYY-MM-DD");
  }
};
</script>
