<template>
  <section class="d-flex flex-column">
    <v-card elevation="1" class="mb-3">
      <v-card-title>Receipts</v-card-title>
      <v-data-table
        :headers="headers"
        :items="receipts"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
      >
      </v-data-table>
      <div class="d-flex flex-row-reverse mt-3 mr-3 mb-3">
        <vue-json-to-csv
          :json-data="compReceiptsDownload"
          :csv-title="'RECEIPTS'"
        >
          <v-btn color="primary" text>
            Download
          </v-btn>
        </vue-json-to-csv>
      </div>
    </v-card>
  </section>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
const moment = require("moment");
import VueJsonToCsv from "vue-json-to-csv";
import helpers from "@/plugins/helpers";
export default {
  name: "Receipts",
  components: {
    VueJsonToCsv
  },
  props: {
    dateStart: String
  },
  data() {
    return {
      receipts: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "_id"
        },

        { text: "Date", value: "createdOn" },
        { text: "Tender Type", value: "tenderType" },
        { text: "Parent ID", value: "parentID" }
      ]
    };
  },
  methods: {
    getReceipts() {
      let vm = this;
      vm.receipts = [];
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };

      axios
        .get(`${vm.$root.urls.api}/v4/receipts`)
        .then(response => {
          console.log(response);
          vm.receipts = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    compReceiptsDownload() {
      let receipts = this.receipts;
      let receiptsDownload = [];
      receipts.forEach(receipt => {
        let receiptDownload = {
          services: receipt.service
            ? JSON.stringify(receipt.service).replace(/,/g, "\t")
            : "",
          ...receipt
        };
        receiptsDownload.push(receiptDownload);
      });
      return receiptsDownload;
    }
  },
  mounted() {
    this.getReceipts();
  }
};
</script>
